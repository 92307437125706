"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.verifySignature = exports.sign = void 0;
const elliptic_1 = require("elliptic");
const utils_1 = require("../utils");
const necc = __importStar(require("@noble/secp256k1"));
const crypto_1 = require("crypto");
const ec = new elliptic_1.ec('secp256k1');
necc.utils.sha256Sync = (...messages) => {
    const sha256 = (0, crypto_1.createHash)('sha256');
    for (const message of messages)
        sha256.update(message);
    return sha256.digest();
};
necc.utils.hmacSha256Sync = (key, ...messages) => {
    const hash = (0, crypto_1.createHmac)('sha256', key);
    messages.forEach((m) => hash.update(m));
    return Uint8Array.from(hash.digest());
};
// hash has to be 32 bytes
function sign(hash, privateKey, _keyType) {
    const keyType = _keyType ?? 'default';
    if (keyType === 'default') {
        const key = ec.keyFromPrivate(privateKey);
        const signature = key.sign(hash);
        return (0, utils_1.encodeSignature)(signature);
    }
    else {
        const signature = necc.schnorr.signSync((0, utils_1.hexToBinUnsafe)(hash), (0, utils_1.hexToBinUnsafe)(privateKey));
        return (0, utils_1.binToHex)(signature);
    }
}
exports.sign = sign;
function verifySignature(hash, publicKey, signature, _keyType) {
    const keyType = _keyType ?? 'default';
    try {
        if (keyType === 'default') {
            const key = ec.keyFromPublic(publicKey, 'hex');
            return key.verify(hash, (0, utils_1.signatureDecode)(ec, signature));
        }
        else {
            return necc.schnorr.verifySync((0, utils_1.hexToBinUnsafe)(signature), (0, utils_1.hexToBinUnsafe)(hash), (0, utils_1.hexToBinUnsafe)(publicKey));
        }
    }
    catch (error) {
        return false;
    }
}
exports.verifySignature = verifySignature;
