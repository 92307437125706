"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.validateNFTBaseUri = exports.validateNFTCollectionUriMetaData = exports.validateNFTTokenUriMetaData = exports.validNFTCollectionUriMetaDataFields = exports.validNFTUriMetaDataAttributeTypes = exports.validNFTTokenUriMetaDataAttributesFields = exports.validNFTTokenUriMetaDataFields = void 0;
// JSON Schema for the NFT metadata, which is pointed to by the value
// returned from the `getTokenUri` method of the NFT contract
require("cross-fetch/polyfill");
exports.validNFTTokenUriMetaDataFields = ['name', 'description', 'image', 'attributes'];
exports.validNFTTokenUriMetaDataAttributesFields = ['trait_type', 'value'];
exports.validNFTUriMetaDataAttributeTypes = ['string', 'number', 'boolean'];
exports.validNFTCollectionUriMetaDataFields = ['name', 'description', 'image'];
function validateNFTTokenUriMetaData(metadata) {
    Object.keys(metadata).forEach((key) => {
        if (!exports.validNFTTokenUriMetaDataFields.includes(key)) {
            throw new Error(`Invalid field ${key}, only ${exports.validNFTTokenUriMetaDataFields} are allowed`);
        }
    });
    const name = validateNonEmptyString(metadata, 'name');
    const description = validateNonEmptyStringIfExists(metadata, 'description');
    const image = validateNonEmptyString(metadata, 'image');
    const attributes = validateNFTTokenUriMetaDataAttributes(metadata['attributes']);
    return { name, description, image, attributes };
}
exports.validateNFTTokenUriMetaData = validateNFTTokenUriMetaData;
function validateNFTCollectionUriMetaData(metadata) {
    Object.keys(metadata).forEach((key) => {
        if (!exports.validNFTCollectionUriMetaDataFields.includes(key)) {
            throw new Error(`Invalid field ${key}, only ${exports.validNFTCollectionUriMetaDataFields} are allowed`);
        }
    });
    const name = validateNonEmptyString(metadata, 'name');
    const description = validateNonEmptyString(metadata, 'description');
    const image = validateNonEmptyString(metadata, 'image');
    return { name, description, image };
}
exports.validateNFTCollectionUriMetaData = validateNFTCollectionUriMetaData;
async function validateNFTBaseUri(nftBaseUri, maxSupply) {
    if (isInteger(maxSupply) && maxSupply > 0) {
        const nftMetadataz = [];
        for (let i = 0; i < maxSupply; i++) {
            const nftMetadata = await fetchNFTMetadata(nftBaseUri, i);
            const validatedNFTMetadata = validateNFTTokenUriMetaData(nftMetadata);
            nftMetadataz.push(validatedNFTMetadata);
        }
        return nftMetadataz;
    }
    else {
        throw new Error('maxSupply should be a positive integer');
    }
}
exports.validateNFTBaseUri = validateNFTBaseUri;
function validateNFTTokenUriMetaDataAttributes(attributes) {
    if (!!attributes) {
        if (!Array.isArray(attributes)) {
            throw new Error(`Field 'attributes' should be an array`);
        }
        attributes.forEach((item) => {
            if (typeof item !== 'object') {
                throw new Error(`Field 'attributes' should be an array of objects`);
            }
            Object.keys(item).forEach((key) => {
                if (!exports.validNFTTokenUriMetaDataAttributesFields.includes(key)) {
                    throw new Error(`Invalid field ${key} for attributes, only ${exports.validNFTTokenUriMetaDataAttributesFields} are allowed`);
                }
            });
            validateNonEmptyString(item, 'trait_type');
            validateNonEmptyAttributeValue(item, 'value');
        });
    }
    return attributes;
}
function validateNonEmptyString(obj, field) {
    const value = obj[`${field}`];
    if (!(typeof value === 'string' && value !== '')) {
        throw new Error(`JSON field '${field}' is not a non empty string`);
    }
    return value;
}
function validateNonEmptyStringIfExists(obj, field) {
    const value = obj[`${field}`];
    if (value !== undefined && !(typeof value === 'string' && value !== '')) {
        throw new Error(`JSON field '${field}' is not a non empty string`);
    }
    return value;
}
function validateNonEmptyAttributeValue(obj, field) {
    const value = obj[`${field}`];
    if (!((typeof value === 'string' && value !== '') || typeof value === 'number' || typeof value === 'boolean')) {
        throw new Error(`Attribute value should be a non empty string, number or boolean`);
    }
    return value;
}
async function fetchNFTMetadata(nftBaseUri, index) {
    try {
        return await (await fetch(`${nftBaseUri}${index}`)).json();
    }
    catch (e) {
        throw new Error(`Error fetching NFT metadata from ${nftBaseUri}${index}: ${e}`);
    }
}
function isInteger(num) {
    return num === parseInt(num.toString(), 10);
}
