"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.i32Codec = exports.i256Codec = exports.Signed = exports.u32Codec = exports.u256Codec = exports.UnSigned = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const codec_1 = require("./codec");
const bigint_codec_1 = require("./bigint-codec");
const maskRest = 0xc0;
const maskMode = 0x3f;
const maskModeNeg = 0xffffffc0;
const SingleByteMode = { type: 'SingleByte', prefix: 0x00, negPrefix: 0xc0 };
const TwoByteMode = { type: 'TwoByte', prefix: 0x40, negPrefix: 0x80 };
const FourByteMode = { type: 'FourByte', prefix: 0x80, negPrefix: 0x40 };
const MultiByte = { type: 'MultiByte', prefix: 0xc0 };
function decodeMode(input) {
    const byte = input.consumeByte();
    switch (byte & maskRest) {
        case SingleByteMode.prefix:
            return { mode: SingleByteMode, body: new Uint8Array([byte]) };
        case TwoByteMode.prefix:
            return { mode: TwoByteMode, body: new Uint8Array([byte, ...input.consumeBytes(1)]) };
        case FourByteMode.prefix:
            return { mode: FourByteMode, body: new Uint8Array([byte, ...input.consumeBytes(3)]) };
        default: {
            const length = (byte & maskMode) + 4;
            return { mode: MultiByte, body: new Uint8Array([byte, ...input.consumeBytes(length)]) };
        }
    }
}
class UnSigned {
    static encodeU32(value) {
        (0, codec_1.assert)(value >= 0 && value < UnSigned.u32UpperBound, `Invalid u32 value: ${value}`);
        if (value < UnSigned.oneByteBound) {
            return new Uint8Array([(SingleByteMode.prefix + value) & 0xff]);
        }
        else if (value < UnSigned.twoByteBound) {
            return new Uint8Array([(TwoByteMode.prefix + (value >> 8)) & 0xff, value & 0xff]);
        }
        else if (value < UnSigned.fourByteBound) {
            return new Uint8Array([
                (FourByteMode.prefix + (value >> 24)) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
        else {
            return new Uint8Array([
                MultiByte.prefix,
                (value >> 24) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
    }
    static encodeU256(value) {
        (0, codec_1.assert)(value >= 0n && value < UnSigned.u256UpperBound, `Invalid u256 value: ${value}`);
        if (value < UnSigned.fourByteBound) {
            return UnSigned.encodeU32(Number(value));
        }
        else {
            let bytes = bigint_codec_1.BigIntCodec.encode(value);
            if (bytes[0] === 0) {
                bytes = bytes.slice(1);
            }
            const header = (bytes.length - 4 + MultiByte.prefix) & 0xff;
            return new Uint8Array([header, ...bytes]);
        }
    }
    static decodeInt(mode, body) {
        switch (mode.type) {
            case 'SingleByte':
                (0, codec_1.assert)(body.length === 1, 'Length should be 2');
                return body[0];
            case 'TwoByte':
                (0, codec_1.assert)(body.length === 2, 'Length should be 2');
                return ((body[0] & maskMode) << 8) | (body[1] & 0xff);
            case 'FourByte':
                (0, codec_1.assert)(body.length === 4, 'Length should be 4');
                return ((((body[0] & maskMode) << 24) | ((body[1] & 0xff) << 16) | ((body[2] & 0xff) << 8) | (body[3] & 0xff)) >>> 0);
        }
    }
    static decodeU32(mode, body) {
        switch (mode.type) {
            case 'SingleByte':
            case 'TwoByte':
            case 'FourByte':
                return UnSigned.decodeInt(mode, body);
            case 'MultiByte':
                (0, codec_1.assert)(body.length >= 5, 'Length should be greater than 5');
                if (body.length === 5) {
                    return ((body[1] << 24) | ((body[2] & 0xff) << 16) | ((body[3] & 0xff) << 8) | (body[4] & 0xff)) >>> 0;
                }
                else {
                    throw new Error(`Expect 4 bytes int, but get ${body.length - 1} bytes int`);
                }
        }
    }
    static decodeU256(mode, body) {
        switch (mode.type) {
            case 'SingleByte':
            case 'TwoByte':
            case 'FourByte':
                return BigInt(UnSigned.decodeInt(mode, body));
            case 'MultiByte':
                return bigint_codec_1.BigIntCodec.decodeUnsigned(body.slice(1, body.length));
        }
    }
}
exports.UnSigned = UnSigned;
UnSigned.oneByteBound = BigInt(0x40);
UnSigned.twoByteBound = UnSigned.oneByteBound << BigInt(8);
UnSigned.fourByteBound = UnSigned.oneByteBound << BigInt(8 * 3);
UnSigned.u256UpperBound = BigInt(1) << BigInt(256);
UnSigned.u32UpperBound = 2 ** 32;
exports.u256Codec = new (class extends codec_1.Codec {
    encode(input) {
        return UnSigned.encodeU256(input);
    }
    _decode(input) {
        const { mode, body } = decodeMode(input);
        return UnSigned.decodeU256(mode, body);
    }
})();
exports.u32Codec = new (class extends codec_1.Codec {
    encode(input) {
        return UnSigned.encodeU32(input);
    }
    _decode(input) {
        const { mode, body } = decodeMode(input);
        return UnSigned.decodeU32(mode, body);
    }
})();
class Signed {
    static encodeI32(value) {
        (0, codec_1.assert)(value >= Signed.i32LowerBound && value < Signed.i32UpperBound, `Invalid i32 value: ${value}`);
        if (value >= 0) {
            return Signed.encodePositiveI32(value);
        }
        else {
            return Signed.encodeNegativeI32(value);
        }
    }
    static encodePositiveI32(value) {
        if (value < this.oneByteBound) {
            return new Uint8Array([(SingleByteMode.prefix + value) & 0xff]);
        }
        else if (value < this.twoByteBound) {
            return new Uint8Array([(TwoByteMode.prefix + (value >> 8)) & 0xff, value & 0xff]);
        }
        else if (value < this.fourByteBound) {
            return new Uint8Array([
                (FourByteMode.prefix + (value >> 24)) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
        else {
            return new Uint8Array([
                MultiByte.prefix,
                (value >> 24) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
    }
    static encodeNegativeI32(value) {
        if (value >= -this.oneByteBound) {
            return new Uint8Array([(value ^ SingleByteMode.negPrefix) & 0xff]);
        }
        else if (value >= -this.twoByteBound) {
            return new Uint8Array([((value >> 8) ^ TwoByteMode.negPrefix) & 0xff, value & 0xff]);
        }
        else if (value >= -this.fourByteBound) {
            return new Uint8Array([
                ((value >> 24) ^ FourByteMode.negPrefix) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
        else {
            return new Uint8Array([
                MultiByte.prefix,
                (value >> 24) & 0xff,
                (value >> 16) & 0xff,
                (value >> 8) & 0xff,
                value & 0xff
            ]);
        }
    }
    static encodeI256(value) {
        (0, codec_1.assert)(value >= Signed.i256LowerBound && value < Signed.i256UpperBound, `Invalid i256 value: ${value}`);
        if (value >= -0x20000000 && value < 0x20000000) {
            return this.encodeI32(Number(value));
        }
        else {
            const bytes = bigint_codec_1.BigIntCodec.encode(value);
            const header = (bytes.length - 4 + MultiByte.prefix) & 0xff;
            return new Uint8Array([header, ...bytes]);
        }
    }
    static decodeInt(mode, body) {
        const isPositive = (body[0] & Signed.signFlag) === 0;
        if (isPositive) {
            return Signed.decodePositiveInt(mode, body);
        }
        else {
            return Signed.decodeNegativeInt(mode, body);
        }
    }
    static decodePositiveInt(mode, body) {
        switch (mode.type) {
            case 'SingleByte':
                return body[0];
            case 'TwoByte':
                (0, codec_1.assert)(body.length === 2, 'Length should be 2');
                return ((body[0] & maskMode) << 8) | (body[1] & 0xff);
            case 'FourByte':
                (0, codec_1.assert)(body.length === 4, 'Length should be 4');
                return ((body[0] & maskMode) << 24) | ((body[1] & 0xff) << 16) | ((body[2] & 0xff) << 8) | (body[3] & 0xff);
        }
    }
    static decodeNegativeInt(mode, body) {
        switch (mode.type) {
            case 'SingleByte':
                return body[0] | maskModeNeg;
            case 'TwoByte':
                (0, codec_1.assert)(body.length === 2, 'Length should be 2');
                return ((body[0] | maskModeNeg) << 8) | (body[1] & 0xff);
            case 'FourByte':
                (0, codec_1.assert)(body.length === 4, 'Length should be 4');
                return ((body[0] | maskModeNeg) << 24) | ((body[1] & 0xff) << 16) | ((body[2] & 0xff) << 8) | (body[3] & 0xff);
        }
    }
    static decodeI32(mode, body) {
        switch (mode.type) {
            case 'SingleByte':
            case 'TwoByte':
            case 'FourByte':
                return Signed.decodeInt(mode, body);
            case 'MultiByte':
                if (body.length === 5) {
                    return (body[1] << 24) | ((body[2] & 0xff) << 16) | ((body[3] & 0xff) << 8) | (body[4] & 0xff);
                }
                else {
                    throw new Error(`Expect 4 bytes int, but get ${body.length - 1} bytes int`);
                }
        }
    }
    static decodeI256(mode, body) {
        switch (mode.type) {
            case 'SingleByte':
            case 'TwoByte':
            case 'FourByte':
                return BigInt(Signed.decodeInt(mode, body));
            case 'MultiByte':
                const bytes = body.slice(1, body.length);
                (0, codec_1.assert)(bytes.length <= 32, 'Expect <= 32 bytes for I256');
                return bigint_codec_1.BigIntCodec.decodeSigned(bytes);
        }
    }
}
exports.Signed = Signed;
Signed.signFlag = 0x20; // 0b00100000
Signed.oneByteBound = BigInt(0x20);
Signed.twoByteBound = Signed.oneByteBound << BigInt(8);
Signed.fourByteBound = Signed.oneByteBound << BigInt(8 * 3);
Signed.i256UpperBound = BigInt(1) << BigInt(255);
Signed.i256LowerBound = -Signed.i256UpperBound;
Signed.i32UpperBound = 2 ** 31;
Signed.i32LowerBound = -Signed.i32UpperBound;
exports.i256Codec = new (class extends codec_1.Codec {
    encode(input) {
        return Signed.encodeI256(input);
    }
    _decode(input) {
        const { mode, body } = decodeMode(input);
        return Signed.decodeI256(mode, body);
    }
})();
exports.i32Codec = new (class extends codec_1.Codec {
    encode(input) {
        return Signed.encodeI32(input);
    }
    _decode(input) {
        const { mode, body } = decodeMode(input);
        return Signed.decodeI32(mode, body);
    }
})();
