"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.methodsCodec = exports.methodCodec = exports.MethodCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const array_codec_1 = require("./array-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const codec_1 = require("./codec");
const instr_codec_1 = require("./instr-codec");
const utils_1 = require("../utils");
function decodeAssetModifier(encoded) {
    const usePayToContractOnly = (encoded & 4) !== 0;
    switch (encoded & 3) {
        case 0:
            return { usePayToContractOnly, usePreapprovedAssets: false, useContractAssets: false };
        case 1:
            return { usePayToContractOnly, usePreapprovedAssets: true, useContractAssets: true };
        case 2:
            return { usePayToContractOnly, usePreapprovedAssets: false, useContractAssets: true };
        case 3:
            return { usePayToContractOnly, usePreapprovedAssets: true, useContractAssets: false };
        default:
            throw new Error(`Invalid asset modifier: ${encoded}`);
    }
}
function encodeAssetModifier(arg) {
    const encoded = !arg.usePreapprovedAssets && !arg.useContractAssets
        ? 0
        : arg.usePreapprovedAssets && arg.useContractAssets
            ? 1
            : !arg.usePreapprovedAssets && arg.useContractAssets
                ? 2
                : 3;
    return encoded | (arg.usePayToContractOnly ? 4 : 0);
}
class MethodCodec extends codec_1.Codec {
    encode(method) {
        const bytes = [];
        bytes.push(codec_1.boolCodec.encode(method.isPublic));
        bytes.push(new Uint8Array([encodeAssetModifier(method)]));
        bytes.push(compact_int_codec_1.i32Codec.encode(method.argsLength));
        bytes.push(compact_int_codec_1.i32Codec.encode(method.localsLength));
        bytes.push(compact_int_codec_1.i32Codec.encode(method.returnLength));
        bytes.push(instr_codec_1.instrsCodec.encode(method.instrs));
        return (0, utils_1.concatBytes)(bytes);
    }
    _decode(input) {
        const isPublic = codec_1.boolCodec._decode(input);
        const assetModifier = decodeAssetModifier(codec_1.byteCodec._decode(input));
        const argsLength = compact_int_codec_1.i32Codec._decode(input);
        const localsLength = compact_int_codec_1.i32Codec._decode(input);
        const returnLength = compact_int_codec_1.i32Codec._decode(input);
        const instrs = instr_codec_1.instrsCodec._decode(input);
        return { ...assetModifier, isPublic, argsLength, localsLength, returnLength, instrs };
    }
}
exports.MethodCodec = MethodCodec;
exports.methodCodec = new MethodCodec();
exports.methodsCodec = new array_codec_1.ArrayCodec(exports.methodCodec);
