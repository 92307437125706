"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.lockupScriptCodec = exports.p2cCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const compact_int_codec_1 = require("./compact-int-codec");
const codec_1 = require("./codec");
const array_codec_1 = require("./array-codec");
exports.p2cCodec = codec_1.byte32Codec;
const p2mpkhCodec = new codec_1.ObjectCodec({
    publicKeyHashes: new array_codec_1.ArrayCodec(codec_1.byte32Codec),
    m: compact_int_codec_1.i32Codec
});
exports.lockupScriptCodec = new codec_1.EnumCodec('lockup script', {
    P2PKH: codec_1.byte32Codec,
    P2MPKH: p2mpkhCodec,
    P2SH: codec_1.byte32Codec,
    P2C: codec_1.byte32Codec
});
