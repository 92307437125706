"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timestampCodec = exports.TimestampCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const utils_1 = require("../utils");
const codec_1 = require("./codec");
class TimestampCodec extends codec_1.Codec {
    encode(input) {
        (0, codec_1.assert)(input >= 0n && input < TimestampCodec.max, `Invalid timestamp: ${input}`);
        const byteArray = new Uint8Array(8);
        for (let index = 0; index < 8; index += 1) {
            byteArray[`${index}`] = Number((input >> BigInt((7 - index) * 8)) & BigInt(0xff));
        }
        return byteArray;
    }
    _decode(input) {
        const bytes = input.consumeBytes(8);
        return BigInt(`0x${(0, utils_1.binToHex)(bytes)}`);
    }
}
exports.TimestampCodec = TimestampCodec;
TimestampCodec.max = 1n << 64n;
exports.timestampCodec = new TimestampCodec();
