"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.tryGetCallResult = exports.NodeProvider = void 0;
const types_1 = require("./types");
const api_alephium_1 = require("./api-alephium");
const utils_1 = require("../utils");
const address_1 = require("../address");
function initializeNodeApi(baseUrl, apiKey, customFetch) {
    const nodeApi = new api_alephium_1.Api({
        baseUrl: baseUrl,
        baseApiParams: { secure: true },
        securityWorker: (accessToken) => (accessToken !== null ? { headers: { 'X-API-KEY': `${accessToken}` } } : {}),
        customFetch: customFetch ?? ((...fetchParams) => fetch(...fetchParams))
    });
    nodeApi.setSecurityData(apiKey ?? null);
    return nodeApi;
}
class NodeProvider {
    constructor(param0, apiKey, customFetch) {
        this.request = (args) => {
            return (0, types_1.request)(this, args);
        };
        // Only use this when the token follows the fungible token interface, check `guessTokenType` first
        this.fetchFungibleTokenMetaData = async (tokenId) => {
            const address = (0, address_1.addressFromTokenId)(tokenId);
            const group = (0, address_1.groupOfAddress)(address);
            const calls = Array.from([0, 1, 2, 3], (index) => ({ methodIndex: index, group: group, address: address }));
            const result = await this.contracts.postContractsMulticallContract({
                calls: calls
            });
            const callResults = result.results.map((r) => tryGetCallResult(r));
            return {
                symbol: callResults[0].returns[0].value,
                name: callResults[1].returns[0].value,
                decimals: Number(callResults[2].returns[0].value),
                totalSupply: BigInt(callResults[3].returns[0].value)
            };
        };
        // Only use this when the token follows the non-fungile token interface, check `guessTokenType` first
        this.fetchNFTMetaData = async (tokenId) => {
            const address = (0, address_1.addressFromTokenId)(tokenId);
            const group = (0, address_1.groupOfAddress)(address);
            const calls = Array.from([0, 1], (index) => ({ methodIndex: index, group: group, address: address }));
            const result = await this.contracts.postContractsMulticallContract({
                calls: calls
            });
            const tokenUri = (0, utils_1.hexToString)(tryGetCallResult(result.results[0]).returns[0].value);
            const collectionIndexResult = result.results[1];
            if (collectionIndexResult.type === 'CallContractSucceeded') {
                const successfulCollectionIndexResult = result.results[1];
                const contractIdReturnResult = successfulCollectionIndexResult.returns[0];
                if (contractIdReturnResult === undefined) {
                    throw new Error('Deprecated NFT contract');
                }
                const collectionId = successfulCollectionIndexResult.returns[0].value;
                if (collectionId === undefined || !(0, utils_1.isHexString)(collectionId) || collectionId.length !== 64) {
                    throw new Error('Deprecated NFT contract');
                }
                const nftIndexReturnResult = successfulCollectionIndexResult.returns[1];
                if (nftIndexReturnResult === undefined) {
                    throw new Error('Deprecated NFT contract');
                }
                const nftIndex = (0, utils_1.toNonNegativeBigInt)(nftIndexReturnResult.value);
                if (nftIndex === undefined) {
                    throw new Error('Deprecated NFT contract');
                }
                // If there are more return values, it is also a deprecated NFT contract
                const thirdResult = successfulCollectionIndexResult.returns[2];
                if (thirdResult !== undefined) {
                    throw new Error('Deprecated NFT contract');
                }
                return { tokenUri, collectionId, nftIndex };
            }
            else {
                const failedCollectionIndexResult = result.results[1];
                if (failedCollectionIndexResult.error.startsWith('VM execution error: Invalid method index')) {
                    throw new Error('Deprecated NFT contract');
                }
                else {
                    throw new Error(`Failed to call contract, error: ${failedCollectionIndexResult.error}`);
                }
            }
        };
        // Only use this when the contract follows the NFT collection interface, check `guessFollowsNFTCollectionStd` first
        this.fetchNFTCollectionMetaData = async (collectionId) => {
            const address = (0, address_1.addressFromContractId)(collectionId);
            const group = (0, address_1.groupOfAddress)(address);
            const calls = Array.from([0, 1], (index) => ({ methodIndex: index, group: group, address: address }));
            const result = await this.contracts.postContractsMulticallContract({ calls });
            const callResults = result.results.map((r) => tryGetCallResult(r));
            return {
                collectionUri: (0, utils_1.hexToString)(callResults[0].returns[0].value),
                totalSupply: BigInt(callResults[1].returns[0].value)
            };
        };
        // Only use this when the contract follows the NFT collection with royalty interface, check `guessFollowsNFTCollectionWithRoyaltyStd` first
        this.fetchNFTRoyaltyAmount = async (collectionId, tokenId, salePrice) => {
            const address = (0, address_1.addressFromContractId)(collectionId);
            const group = (0, address_1.groupOfAddress)(address);
            const apiResult = await this.contracts.postContractsCallContract({
                address: address,
                group: group,
                methodIndex: 4,
                args: [
                    {
                        type: 'ByteVec',
                        value: tokenId
                    },
                    {
                        type: 'U256',
                        value: salePrice.toString()
                    }
                ]
            });
            const result = tryGetCallResult(apiResult);
            return BigInt(result.returns[0].value);
        };
        this.guessStdInterfaceId = async (tokenId) => {
            const address = (0, address_1.addressFromTokenId)(tokenId);
            const rawState = await this.contracts.getContractsAddressState(address);
            const lastImmField = rawState.immFields.slice(-1).pop()?.value;
            const interfaceIdPrefix = '414c5048'; // the hex of 'ALPH'
            if (typeof lastImmField === 'string' && lastImmField.startsWith(interfaceIdPrefix)) {
                return lastImmField.slice(8);
            }
            else {
                return undefined;
            }
        };
        this.guessFollowsNFTCollectionStd = async (contractId) => {
            const interfaceId = await this.guessStdInterfaceId(contractId);
            return !!interfaceId && interfaceId.startsWith(types_1.StdInterfaceIds.NFTCollection);
        };
        this.guessFollowsNFTCollectionWithRoyaltyStd = async (contractId) => {
            const interfaceId = await this.guessStdInterfaceId(contractId);
            return interfaceId === types_1.StdInterfaceIds.NFTCollectionWithRoyalty;
        };
        this.guessStdTokenType = async (tokenId) => {
            const interfaceId = await this.guessStdInterfaceId(tokenId);
            switch (true) {
                case interfaceId?.startsWith(types_1.StdInterfaceIds.FungibleToken):
                    return 'fungible';
                case interfaceId?.startsWith(types_1.StdInterfaceIds.NFT):
                    return 'non-fungible';
                default:
                    return undefined;
            }
        };
        let nodeApi;
        if (typeof param0 === 'string') {
            nodeApi = initializeNodeApi(param0, apiKey, customFetch);
        }
        else if (typeof param0 === 'function') {
            nodeApi = new NodeProvider('https://1.2.3.4:0');
            (0, types_1.forwardRequests)(nodeApi, param0);
        }
        else {
            nodeApi = param0;
        }
        this.wallets = { ...nodeApi.wallets };
        this.infos = { ...nodeApi.infos };
        this.blockflow = { ...nodeApi.blockflow };
        this.addresses = { ...nodeApi.addresses };
        this.transactions = { ...nodeApi.transactions };
        this.mempool = { ...nodeApi.mempool };
        this.contracts = { ...nodeApi.contracts };
        this.multisig = { ...nodeApi.multisig };
        this.utils = { ...nodeApi.utils };
        this.miners = { ...nodeApi.miners };
        this.events = { ...nodeApi.events };
        (0, types_1.requestWithLog)(this);
    }
    // This can prevent the proxied node provider from being modified
    static Proxy(nodeProvider) {
        return new NodeProvider(nodeProvider);
    }
    static Remote(handler) {
        return new NodeProvider(handler);
    }
}
exports.NodeProvider = NodeProvider;
function tryGetCallResult(result) {
    if (result.type === 'CallContractFailed') {
        throw new Error(`Failed to call contract, error: ${result.error}`);
    }
    return result;
}
exports.tryGetCallResult = tryGetCallResult;
