"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.statefulScriptCodecOpt = exports.scriptCodec = exports.ScriptCodec = void 0;
const codec_1 = require("./codec");
const method_codec_1 = require("./method-codec");
const option_codec_1 = require("./option-codec");
class ScriptCodec extends codec_1.Codec {
    encode(input) {
        return method_codec_1.methodsCodec.encode(input.methods);
    }
    _decode(input) {
        return { methods: method_codec_1.methodsCodec._decode(input) };
    }
}
exports.ScriptCodec = ScriptCodec;
exports.scriptCodec = new ScriptCodec();
exports.statefulScriptCodecOpt = (0, option_codec_1.option)(exports.scriptCodec);
