"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.StdInterfaceIds = exports.request = exports.requestWithLog = exports.forwardRequests = exports.getDefaultPrimitiveValue = exports.decodeArrayType = exports.fromApiPrimitiveVal = exports.toApiVal = exports.toApiArray = exports.toApiAddress = exports.toApiByteVec = exports.fromApiNumber256 = exports.toApiNumber256Optional = exports.toApiNumber256 = exports.toApiBoolean = exports.fromApiTokens = exports.fromApiToken = exports.toApiTokens = exports.toApiToken = exports.PrimitiveTypes = void 0;
const constants_1 = require("../constants");
const debug_1 = require("../debug");
const utils_1 = require("../utils");
exports.PrimitiveTypes = ['U256', 'I256', 'Bool', 'ByteVec', 'Address'];
utils_1.assertType;
function toApiToken(token) {
    return { id: token.id, amount: toApiNumber256(token.amount) };
}
exports.toApiToken = toApiToken;
function toApiTokens(tokens) {
    return tokens?.map(toApiToken);
}
exports.toApiTokens = toApiTokens;
function fromApiToken(token) {
    return { id: token.id, amount: fromApiNumber256(token.amount) };
}
exports.fromApiToken = fromApiToken;
function fromApiTokens(tokens) {
    return tokens?.map(fromApiToken);
}
exports.fromApiTokens = fromApiTokens;
function toApiBoolean(v) {
    if (typeof v === 'boolean') {
        return v;
    }
    else {
        throw new Error(`Invalid boolean value: ${v}`);
    }
}
exports.toApiBoolean = toApiBoolean;
// TODO: check integer bounds
function toApiNumber256(v) {
    if ((typeof v === 'number' && Number.isInteger(v)) || typeof v === 'bigint') {
        return v.toString();
    }
    else if (typeof v === 'string') {
        try {
            if (BigInt(v).toString() === v) {
                return v;
            }
        }
        catch (_) {
            throw new Error(`Invalid value: ${v}, expected a 256 bit number`);
        }
    }
    throw new Error(`Invalid value: ${v}, expected a 256 bit number`);
}
exports.toApiNumber256 = toApiNumber256;
function toApiNumber256Optional(v) {
    return v === undefined ? undefined : toApiNumber256(v);
}
exports.toApiNumber256Optional = toApiNumber256Optional;
function fromApiNumber256(n) {
    return BigInt(n);
}
exports.fromApiNumber256 = fromApiNumber256;
function toApiByteVec(v) {
    if (typeof v !== 'string') {
        throw new Error(`Invalid value: ${v}, expected a hex-string`);
    }
    if ((0, utils_1.isHexString)(v))
        return v;
    if ((0, utils_1.isBase58)(v)) {
        // try to convert from address to contract id
        try {
            const address = utils_1.bs58.decode(v);
            if (address.length == 33 && address[0] == 3) {
                return (0, utils_1.binToHex)(address.slice(1));
            }
        }
        catch (_) {
            throw new Error(`Invalid hex-string: ${v}`);
        }
    }
    throw new Error(`Invalid hex-string: ${v}`);
}
exports.toApiByteVec = toApiByteVec;
function toApiAddress(v) {
    if (typeof v === 'string') {
        try {
            utils_1.bs58.decode(v);
            return v;
        }
        catch (error) {
            throw new Error(`Invalid base58 string: ${v}`);
        }
    }
    else {
        throw new Error(`Invalid value: ${v}, expected a base58 string`);
    }
}
exports.toApiAddress = toApiAddress;
function toApiArray(tpe, v) {
    if (!Array.isArray(v)) {
        throw new Error(`Expected array, got ${v}`);
    }
    const semiColonIndex = tpe.lastIndexOf(';');
    if (semiColonIndex == -1) {
        throw new Error(`Invalid Val type: ${tpe}`);
    }
    const subType = tpe.slice(1, semiColonIndex);
    const dim = parseInt(tpe.slice(semiColonIndex + 1, -1));
    if (v.length != dim) {
        throw new Error(`Invalid val dimension: ${v}`);
    }
    else {
        return { value: v.map((v) => toApiVal(v, subType)), type: 'Array' };
    }
}
exports.toApiArray = toApiArray;
function toApiVal(v, tpe) {
    if (tpe === 'Bool') {
        return { value: toApiBoolean(v), type: tpe };
    }
    else if (tpe === 'U256' || tpe === 'I256') {
        return { value: toApiNumber256(v), type: tpe };
    }
    else if (tpe === 'ByteVec') {
        return { value: toApiByteVec(v), type: tpe };
    }
    else if (tpe === 'Address') {
        return { value: toApiAddress(v), type: tpe };
    }
    else {
        return toApiArray(tpe, v);
    }
}
exports.toApiVal = toApiVal;
function fromApiPrimitiveVal(value, tpe, systemEvent = false) {
    if (tpe === 'Bool' && value.type === tpe) {
        return value.value;
    }
    else if ((tpe === 'U256' || tpe === 'I256') && value.type === tpe) {
        return fromApiNumber256(value.value);
    }
    else if ((tpe === 'ByteVec' || tpe === 'Address') && (value.type === tpe || systemEvent)) {
        return value.value;
    }
    else {
        throw new Error(`Expected primitive type, got ${tpe}`);
    }
}
exports.fromApiPrimitiveVal = fromApiPrimitiveVal;
function decodeArrayType(tpe) {
    const semiColonIndex = tpe.lastIndexOf(';');
    if (semiColonIndex === -1) {
        throw new Error(`Invalid array type: ${tpe}`);
    }
    const baseType = tpe.slice(1, semiColonIndex);
    const size = parseInt(tpe.slice(semiColonIndex + 1, -1));
    return [baseType, size];
}
exports.decodeArrayType = decodeArrayType;
function getDefaultPrimitiveValue(tpe) {
    if (tpe === 'U256' || tpe === 'I256')
        return 0n;
    if (tpe === 'Bool')
        return false;
    if (tpe === 'ByteVec')
        return '';
    if (tpe === 'Address')
        return constants_1.ZERO_ADDRESS;
    throw Error(`Expected primitive type, got ${tpe}`);
}
exports.getDefaultPrimitiveValue = getDefaultPrimitiveValue;
async function call(args, handler) {
    const debugModeEnabled = (0, debug_1.isDebugModeEnabled)();
    const { path, method, params } = args;
    if (debugModeEnabled) {
        console.log(`[REQUEST] ${path} ${method} ${JSON.stringify(params)}`);
    }
    try {
        const response = await handler(args);
        if (debugModeEnabled) {
            console.log(`[RESPONSE] ${path} ${method} ${JSON.stringify(response)}`);
        }
        return response;
    }
    catch (error) {
        if (debugModeEnabled) {
            console.error(`[ERROR] ${path} ${method} `, error);
        }
        throw error;
    }
}
function forwardRequests(api, handler) {
    // Update class properties to forward requests
    for (const [path, pathObject] of Object.entries(api)) {
        for (const method of Object.keys(pathObject)) {
            pathObject[`${method}`] = async (...params) => {
                return call({ path, method, params }, handler);
            };
        }
    }
}
exports.forwardRequests = forwardRequests;
function requestWithLog(api) {
    for (const [path, pathObject] of Object.entries(api)) {
        for (const [method, handler] of Object.entries(pathObject)) {
            pathObject[`${method}`] = async (...params) => {
                return call({ path, method, params }, () => handler(...params));
            };
        }
    }
}
exports.requestWithLog = requestWithLog;
async function request(provider, args) {
    const call = provider[`${args.path}`][`${args.method}`];
    return call(...args.params);
}
exports.request = request;
var StdInterfaceIds;
(function (StdInterfaceIds) {
    StdInterfaceIds["FungibleToken"] = "0001";
    StdInterfaceIds["NFTCollection"] = "0002";
    StdInterfaceIds["NFT"] = "0003";
    StdInterfaceIds["NFTCollectionWithRoyalty"] = "000201";
})(StdInterfaceIds = exports.StdInterfaceIds || (exports.StdInterfaceIds = {}));
