"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assetOutputsCodec = exports.assetOutputCodec = exports.AssetOutputCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const array_codec_1 = require("./array-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const int_as_4bytes_codec_1 = require("./int-as-4bytes-codec");
const timestamp_codec_1 = require("./timestamp-codec");
const bytestring_codec_1 = require("./bytestring-codec");
const lockup_script_codec_1 = require("./lockup-script-codec");
const hash_1 = require("./hash");
const utils_1 = require("../utils");
const codec_1 = require("./codec");
const token_codec_1 = require("./token-codec");
class AssetOutputCodec extends codec_1.ObjectCodec {
    static toFixedAssetOutputs(txIdBytes, outputs) {
        return outputs.map((output, index) => AssetOutputCodec.toFixedAssetOutput(txIdBytes, output, index));
    }
    static toFixedAssetOutput(txIdBytes, output, index) {
        const attoAlphAmount = output.amount.toString();
        const lockTime = Number(output.lockTime);
        const tokens = output.tokens.map((token) => {
            return {
                id: (0, utils_1.binToHex)(token.tokenId),
                amount: token.amount.toString()
            };
        });
        const message = (0, utils_1.binToHex)(output.additionalData);
        const scriptType = output.lockupScript.kind;
        const key = (0, utils_1.binToHex)((0, hash_1.blakeHash)((0, utils_1.concatBytes)([txIdBytes, int_as_4bytes_codec_1.intAs4BytesCodec.encode(index)])));
        const outputLockupScript = output.lockupScript.value;
        const address = utils_1.bs58.encode(lockup_script_codec_1.lockupScriptCodec.encode(output.lockupScript));
        let hint = undefined;
        if (scriptType === 'P2PKH') {
            hint = (0, hash_1.createHint)(outputLockupScript);
        }
        else if (scriptType === 'P2MPKH') {
            hint = (0, hash_1.createHint)(outputLockupScript.publicKeyHashes[0]);
        }
        else if (scriptType === 'P2SH') {
            hint = (0, hash_1.createHint)(outputLockupScript);
        }
        else if (scriptType === 'P2C') {
            throw new Error(`P2C script type not allowed for asset output`);
        }
        else {
            throw new Error(`Unexpected output script type: ${scriptType}`);
        }
        return { hint, key, attoAlphAmount, lockTime, tokens, address, message };
    }
    static fromFixedAssetOutputs(fixedOutputs) {
        return fixedOutputs.map((output) => {
            return AssetOutputCodec.fromFixedAssetOutput(output);
        });
    }
    static fromFixedAssetOutput(fixedOutput) {
        const amount = BigInt(fixedOutput.attoAlphAmount);
        const lockTime = BigInt(fixedOutput.lockTime);
        const lockupScript = lockup_script_codec_1.lockupScriptCodec.decode(utils_1.bs58.decode(fixedOutput.address));
        const tokens = fixedOutput.tokens.map((token) => {
            return {
                tokenId: (0, utils_1.hexToBinUnsafe)(token.id),
                amount: BigInt(token.amount)
            };
        });
        const additionalData = (0, utils_1.hexToBinUnsafe)(fixedOutput.message);
        return { amount, lockupScript, lockTime, tokens, additionalData };
    }
}
exports.AssetOutputCodec = AssetOutputCodec;
exports.assetOutputCodec = new AssetOutputCodec({
    amount: compact_int_codec_1.u256Codec,
    lockupScript: lockup_script_codec_1.lockupScriptCodec,
    lockTime: timestamp_codec_1.timestampCodec,
    tokens: token_codec_1.tokensCodec,
    additionalData: bytestring_codec_1.byteStringCodec
});
exports.assetOutputsCodec = new array_codec_1.ArrayCodec(exports.assetOutputCodec);
