"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.valsCodec = exports.valCodec = void 0;
const compact_int_codec_1 = require("./compact-int-codec");
const bytestring_codec_1 = require("./bytestring-codec");
const codec_1 = require("./codec");
const lockup_script_codec_1 = require("./lockup-script-codec");
const array_codec_1 = require("./array-codec");
exports.valCodec = new codec_1.EnumCodec('val', {
    Bool: codec_1.boolCodec,
    I256: compact_int_codec_1.i256Codec,
    U256: compact_int_codec_1.u256Codec,
    ByteVec: bytestring_codec_1.byteStringCodec,
    Address: lockup_script_codec_1.lockupScriptCodec
});
exports.valsCodec = new array_codec_1.ArrayCodec(exports.valCodec);
