"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExplorerProvider = void 0;
const types_1 = require("./types");
const api_explorer_1 = require("./api-explorer");
function initializeExplorerApi(baseUrl, apiKey, customFetch) {
    const explorerApi = new api_explorer_1.Api({
        baseUrl: baseUrl,
        baseApiParams: { secure: true },
        securityWorker: (accessToken) => (accessToken !== null ? { headers: { 'X-API-KEY': `${accessToken}` } } : {}),
        customFetch: customFetch ?? ((...fetchParams) => fetch(...fetchParams))
    });
    explorerApi.setSecurityData(apiKey ?? null);
    return explorerApi;
}
class ExplorerProvider {
    constructor(param0, apiKey, customFetch) {
        this.request = (args) => {
            return (0, types_1.request)(this, args);
        };
        let explorerApi;
        if (typeof param0 === 'string') {
            explorerApi = initializeExplorerApi(param0, apiKey, customFetch);
        }
        else if (typeof param0 === 'function') {
            explorerApi = new ExplorerProvider('https://1.2.3.4:0');
            (0, types_1.forwardRequests)(explorerApi, param0);
        }
        else {
            explorerApi = param0;
        }
        this.blocks = { ...explorerApi.blocks };
        this.transactions = { ...explorerApi.transactions };
        this.addresses = { ...explorerApi.addresses };
        this.infos = { ...explorerApi.infos };
        this.mempool = { ...explorerApi.mempool };
        this.tokens = { ...explorerApi.tokens };
        this.charts = { ...explorerApi.charts };
        this.utils = { ...explorerApi.utils };
        this.contracts = { ...explorerApi.contracts };
        this.market = { ...explorerApi.market };
        this.contractEvents = { ...explorerApi.contractEvents };
    }
    // This can prevent the proxied explorer provider from being modified
    static Proxy(explorerProvider) {
        return new ExplorerProvider(explorerProvider);
    }
    static Remote(handler) {
        return new ExplorerProvider(handler);
    }
}
exports.ExplorerProvider = ExplorerProvider;
