"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.groupIndexOfTransaction = exports.waitForTxConfirmation = void 0;
const address_1 = require("../address");
const constants_1 = require("../constants");
const global_1 = require("../global");
const utils_1 = require("../utils");
function isConfirmed(txStatus) {
    return txStatus.type === 'Confirmed';
}
async function waitForTxConfirmation(txId, confirmations, requestInterval) {
    const provider = (0, global_1.getCurrentNodeProvider)();
    const status = await provider.transactions.getTransactionsStatus({ txId: txId });
    if (isConfirmed(status) && status.chainConfirmations >= confirmations) {
        return status;
    }
    await new Promise((r) => setTimeout(r, requestInterval));
    return waitForTxConfirmation(txId, confirmations, requestInterval);
}
exports.waitForTxConfirmation = waitForTxConfirmation;
function groupIndexOfTransaction(unsignedTx) {
    if (unsignedTx.inputs.length === 0)
        throw new Error('Empty inputs for unsignedTx');
    const fromGroup = groupFromHint(unsignedTx.inputs[0].hint);
    let toGroup = fromGroup;
    for (const output of unsignedTx.fixedOutputs) {
        const outputGroup = (0, address_1.groupOfLockupScript)(output.lockupScript);
        if (outputGroup !== fromGroup) {
            toGroup = outputGroup;
            break;
        }
    }
    return [fromGroup, toGroup];
}
exports.groupIndexOfTransaction = groupIndexOfTransaction;
function groupFromHint(hint) {
    const hash = (0, utils_1.xorByte)(hint);
    return hash % constants_1.TOTAL_NUMBER_OF_GROUPS;
}
