"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.boolCodec = exports.byteCodec = exports.byte32Codec = exports.EnumCodec = exports.ObjectCodec = exports.FixedSizeCodec = exports.assert = exports.Codec = void 0;
const utils_1 = require("../utils");
const reader_1 = require("./reader");
class Codec {
    decode(input) {
        const reader = new reader_1.Reader(input);
        return this._decode(reader);
    }
    bimap(from, to) {
        return new (class extends Codec {
            constructor(codecT) {
                super();
                this.codecT = codecT;
            }
            encode(input) {
                return this.codecT.encode(to(input));
            }
            _decode(input) {
                return from(this.codecT._decode(input));
            }
        })(this);
    }
}
exports.Codec = Codec;
function assert(value, message) {
    if (!value) {
        throw new Error(message);
    }
}
exports.assert = assert;
class FixedSizeCodec extends Codec {
    constructor(size) {
        super();
        this.size = size;
    }
    encode(input) {
        assert(input.length === this.size, `Invalid length, expected ${this.size}, got ${input.length}`);
        return input;
    }
    _decode(input) {
        return input.consumeBytes(this.size);
    }
}
exports.FixedSizeCodec = FixedSizeCodec;
class ObjectCodec extends Codec {
    constructor(codecs) {
        super();
        this.codecs = codecs;
        this.keys = Object.keys(codecs);
    }
    encode(value) {
        const bytes = [];
        for (const key of this.keys) {
            bytes.push(this.codecs[key].encode(value[key]));
        }
        return (0, utils_1.concatBytes)(bytes);
    }
    _decode(input) {
        const result = {};
        for (const key of this.keys) {
            result[key] = this.codecs[key]._decode(input);
        }
        return result;
    }
}
exports.ObjectCodec = ObjectCodec;
class EnumCodec extends Codec {
    constructor(name, codecs) {
        super();
        this.name = name;
        this.codecs = codecs;
        this.kinds = Object.keys(codecs);
    }
    encode(value) {
        const index = this.kinds.findIndex((t) => t === value.kind);
        if (index === -1) {
            throw new Error(`Invalid ${this.name} kind ${value.kind}, expected one of ${this.kinds}`);
        }
        const codec = this.codecs[value.kind];
        return new Uint8Array([index, ...codec.encode(value.value)]);
    }
    _decode(input) {
        const index = input.consumeByte();
        if (index >= 0 && index < this.kinds.length) {
            const kind = this.kinds[`${index}`];
            const codec = this.codecs[kind];
            return { kind, value: codec._decode(input) };
        }
        throw new Error(`Invalid encoded ${this.name} kind: ${index}`);
    }
}
exports.EnumCodec = EnumCodec;
exports.byte32Codec = new FixedSizeCodec(32);
exports.byteCodec = new (class extends Codec {
    encode(input) {
        assert(input >= 0 && input < 256, `Invalid byte: ${input}`);
        return new Uint8Array([input]);
    }
    _decode(input) {
        return input.consumeByte();
    }
})();
exports.boolCodec = new (class extends Codec {
    encode(input) {
        return new Uint8Array([input ? 1 : 0]);
    }
    _decode(input) {
        const byte = input.consumeByte();
        if (byte === 1) {
            return true;
        }
        else if (byte === 0) {
            return false;
        }
        else {
            throw new Error(`Invalid encoded bool value ${byte}, expected 0 or 1`);
        }
    }
})();
