"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.contractOutputCodec = exports.ContractOutputCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const compact_int_codec_1 = require("./compact-int-codec");
const lockup_script_codec_1 = require("./lockup-script-codec");
const codec_1 = require("./codec");
const token_codec_1 = require("./token-codec");
const hash_1 = require("./hash");
const utils_1 = require("../utils");
const int_as_4bytes_codec_1 = require("./int-as-4bytes-codec");
const lockup_script_codec_2 = require("./lockup-script-codec");
class ContractOutputCodec extends codec_1.ObjectCodec {
    static convertToApiContractOutput(txIdBytes, output, index) {
        const hint = (0, hash_1.createHint)(output.lockupScript);
        const key = (0, utils_1.binToHex)((0, hash_1.blakeHash)((0, utils_1.concatBytes)([txIdBytes, int_as_4bytes_codec_1.intAs4BytesCodec.encode(index)])));
        const attoAlphAmount = output.amount.toString();
        const address = utils_1.bs58.encode(new Uint8Array([0x03, ...output.lockupScript]));
        const tokens = output.tokens.map((token) => {
            return {
                id: (0, utils_1.binToHex)(token.tokenId),
                amount: token.amount.toString()
            };
        });
        return { hint, key, attoAlphAmount, address, tokens, type: 'ContractOutput' };
    }
    static convertToOutput(apiContractOutput) {
        const amount = BigInt(apiContractOutput.attoAlphAmount);
        const lockupScript = lockup_script_codec_2.lockupScriptCodec.decode(utils_1.bs58.decode(apiContractOutput.address)).value;
        const tokens = apiContractOutput.tokens.map((token) => {
            return {
                tokenId: (0, utils_1.hexToBinUnsafe)(token.id),
                amount: BigInt(token.amount)
            };
        });
        return { amount, lockupScript, tokens };
    }
}
exports.ContractOutputCodec = ContractOutputCodec;
exports.contractOutputCodec = new ContractOutputCodec({
    amount: compact_int_codec_1.u256Codec,
    lockupScript: lockup_script_codec_1.p2cCodec,
    tokens: token_codec_1.tokensCodec
});
