"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.transactionCodec = exports.TransactionCodec = void 0;
const unsigned_tx_codec_1 = require("./unsigned-tx-codec");
const signature_codec_1 = require("./signature-codec");
const contract_output_ref_codec_1 = require("./contract-output-ref-codec");
const asset_output_codec_1 = require("./asset-output-codec");
const contract_output_codec_1 = require("./contract-output-codec");
const utils_1 = require("../utils");
const codec_1 = require("./codec");
const output_codec_1 = require("./output-codec");
class TransactionCodec extends codec_1.ObjectCodec {
    encodeApiTransaction(input) {
        const decodedTx = TransactionCodec.fromApiTransaction(input);
        return this.encode(decodedTx);
    }
    decodeApiTransaction(input) {
        const decodedTx = this.decode(input);
        return TransactionCodec.toApiTransaction(decodedTx);
    }
    static toApiTransaction(transaction) {
        const txId = unsigned_tx_codec_1.UnsignedTxCodec.txId(transaction.unsigned);
        const unsigned = unsigned_tx_codec_1.UnsignedTxCodec.toApiUnsignedTx(transaction.unsigned);
        const scriptExecutionOk = !!transaction.scriptExecutionOk;
        const contractInputs = transaction.contractInputs.map((contractInput) => {
            const hint = contractInput.hint;
            const key = (0, utils_1.binToHex)(contractInput.key);
            return { hint, key };
        });
        const txIdBytes = (0, utils_1.hexToBinUnsafe)(txId);
        const generatedOutputs = transaction.generatedOutputs.map((output, index) => {
            if (output.kind === 'Left') {
                const fixedAssetOutput = asset_output_codec_1.AssetOutputCodec.toFixedAssetOutput(txIdBytes, output.value, index);
                return { ...fixedAssetOutput, type: 'AssetOutput' };
            }
            else {
                return contract_output_codec_1.ContractOutputCodec.convertToApiContractOutput(txIdBytes, output.value, index);
            }
        });
        const inputSignatures = transaction.inputSignatures.map((signature) => (0, utils_1.binToHex)(signature));
        const scriptSignatures = transaction.scriptSignatures.map((signature) => (0, utils_1.binToHex)(signature));
        return { unsigned, scriptExecutionOk, contractInputs, generatedOutputs, inputSignatures, scriptSignatures };
    }
    static fromApiTransaction(tx) {
        const unsigned = unsigned_tx_codec_1.UnsignedTxCodec.fromApiUnsignedTx(tx.unsigned);
        const scriptExecutionOk = tx.scriptExecutionOk ? 1 : 0;
        const contractInputs = tx.contractInputs.map((contractInput) => {
            return { hint: contractInput.hint, key: (0, utils_1.hexToBinUnsafe)(contractInput.key) };
        });
        const generatedOutputs = tx.generatedOutputs.map((output) => {
            if (output.type === 'AssetOutput') {
                return { kind: 'Left', value: asset_output_codec_1.AssetOutputCodec.fromFixedAssetOutput(output) };
            }
            else if (output.type === 'ContractOutput') {
                return { kind: 'Right', value: contract_output_codec_1.ContractOutputCodec.convertToOutput(output) };
            }
            else {
                throw new Error('Invalid output type');
            }
        });
        const inputSignatures = tx.inputSignatures.map((signature) => {
            return (0, utils_1.hexToBinUnsafe)(signature);
        });
        const scriptSignatures = tx.scriptSignatures.map((signature) => {
            return (0, utils_1.hexToBinUnsafe)(signature);
        });
        return {
            unsigned,
            scriptExecutionOk,
            contractInputs: contractInputs,
            generatedOutputs: generatedOutputs,
            inputSignatures: inputSignatures,
            scriptSignatures: scriptSignatures
        };
    }
}
exports.TransactionCodec = TransactionCodec;
exports.transactionCodec = new TransactionCodec({
    unsigned: unsigned_tx_codec_1.unsignedTxCodec,
    scriptExecutionOk: codec_1.byteCodec,
    contractInputs: contract_output_ref_codec_1.contractOutputRefsCodec,
    generatedOutputs: output_codec_1.outputsCodec,
    inputSignatures: signature_codec_1.signaturesCodec,
    scriptSignatures: signature_codec_1.signaturesCodec
});
