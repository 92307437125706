"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.subscribeToTxStatus = exports.TxStatusSubscription = void 0;
const web3 = __importStar(require("../global"));
const utils_1 = require("../utils");
class TxStatusSubscription extends utils_1.Subscription {
    constructor(options, txId, fromGroup, toGroup, confirmations) {
        super(options);
        this.txId = txId;
        this.fromGroup = fromGroup;
        this.toGroup = toGroup;
        this.confirmations = confirmations ?? 1;
    }
    async polling() {
        try {
            const txStatus = await web3.getCurrentNodeProvider().transactions.getTransactionsStatus({
                txId: this.txId,
                fromGroup: this.fromGroup,
                toGroup: this.toGroup
            });
            await this.messageCallback(txStatus);
            if (txStatus.type === 'Confirmed' && txStatus.chainConfirmations >= this.confirmations) {
                this.unsubscribe();
            }
        }
        catch (err) {
            await this.errorCallback(err, this);
        }
    }
}
exports.TxStatusSubscription = TxStatusSubscription;
function subscribeToTxStatus(options, txId, fromGroup, toGroup, confirmations) {
    const subscription = new TxStatusSubscription(options, txId, fromGroup, toGroup, confirmations);
    subscription.subscribe();
    return subscription;
}
exports.subscribeToTxStatus = subscribeToTxStatus;
