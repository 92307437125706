"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.getCurrentExplorerProvider = exports.setCurrentExplorerProvider = exports.getCurrentNodeProvider = exports.setCurrentNodeProvider = void 0;
const api_1 = require("./api");
let _currentNodeProvider = undefined;
function setCurrentNodeProvider(provider, apiKey, customFetch) {
    if (typeof provider == 'string') {
        _currentNodeProvider = new api_1.NodeProvider(provider, apiKey, customFetch);
    }
    else {
        _currentNodeProvider = provider;
    }
}
exports.setCurrentNodeProvider = setCurrentNodeProvider;
function getCurrentNodeProvider() {
    if (typeof _currentNodeProvider === 'undefined') {
        throw Error('No node provider is set.');
    }
    return _currentNodeProvider;
}
exports.getCurrentNodeProvider = getCurrentNodeProvider;
let _currentExplorerProvider = undefined;
function setCurrentExplorerProvider(provider, apiKey, customFetch) {
    if (typeof provider == 'string') {
        _currentExplorerProvider = new api_1.ExplorerProvider(provider, apiKey, customFetch);
    }
    else {
        _currentExplorerProvider = provider;
    }
}
exports.setCurrentExplorerProvider = setCurrentExplorerProvider;
// Different from `NodeProvider`, this may return `undefined`
// as ExplorerProvider is not necessary for all applications
function getCurrentExplorerProvider() {
    return _currentExplorerProvider;
}
exports.getCurrentExplorerProvider = getCurrentExplorerProvider;
