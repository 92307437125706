"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inputsCodec = exports.inputCodec = exports.InputCodec = void 0;
const utils_1 = require("../utils");
const unlock_script_codec_1 = require("./unlock-script-codec");
const codec_1 = require("./codec");
const array_codec_1 = require("./array-codec");
const int_as_4bytes_codec_1 = require("./int-as-4bytes-codec");
class InputCodec extends codec_1.ObjectCodec {
    static toAssetInputs(inputs) {
        return inputs.map((input) => {
            const hint = input.hint;
            const key = (0, utils_1.binToHex)(input.key);
            const unlockScript = unlock_script_codec_1.unlockScriptCodec.encode(input.unlockScript);
            return {
                outputRef: { hint, key },
                unlockScript: (0, utils_1.binToHex)(unlockScript)
            };
        });
    }
    static fromAssetInputs(inputs) {
        return inputs.map((input) => {
            const hint = input.outputRef.hint;
            const key = (0, utils_1.hexToBinUnsafe)(input.outputRef.key);
            const unlockScript = unlock_script_codec_1.unlockScriptCodec.decode((0, utils_1.hexToBinUnsafe)(input.unlockScript));
            return { hint, key, unlockScript };
        });
    }
}
exports.InputCodec = InputCodec;
exports.inputCodec = new InputCodec({
    hint: int_as_4bytes_codec_1.intAs4BytesCodec,
    key: codec_1.byte32Codec,
    unlockScript: unlock_script_codec_1.unlockScriptCodec
});
exports.inputsCodec = new array_codec_1.ArrayCodec(exports.inputCodec);
