"use strict";
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
Object.defineProperty(exports, "__esModule", { value: true });
exports.getALPHDepositInfo = exports.getSenderAddress = exports.isALPHTransferTx = exports.validateExchangeAddress = void 0;
var exchange_1 = require("./exchange");
Object.defineProperty(exports, "validateExchangeAddress", { enumerable: true, get: function () { return exchange_1.validateExchangeAddress; } });
Object.defineProperty(exports, "isALPHTransferTx", { enumerable: true, get: function () { return exchange_1.isALPHTransferTx; } });
Object.defineProperty(exports, "getSenderAddress", { enumerable: true, get: function () { return exchange_1.getSenderAddress; } });
Object.defineProperty(exports, "getALPHDepositInfo", { enumerable: true, get: function () { return exchange_1.getALPHDepositInfo; } });
