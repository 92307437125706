"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unsignedTxCodec = exports.UnsignedTxCodec = void 0;
const utils_1 = require("../utils");
const script_codec_1 = require("./script-codec");
const compact_int_codec_1 = require("./compact-int-codec");
const input_codec_1 = require("./input-codec");
const asset_output_codec_1 = require("./asset-output-codec");
const hash_1 = require("./hash");
const codec_1 = require("./codec");
class UnsignedTxCodec extends codec_1.ObjectCodec {
    encodeApiUnsignedTx(input) {
        const decoded = UnsignedTxCodec.fromApiUnsignedTx(input);
        return this.encode(decoded);
    }
    decodeApiUnsignedTx(input) {
        const decoded = this.decode(input);
        return UnsignedTxCodec.toApiUnsignedTx(decoded);
    }
    static txId(unsignedTx) {
        return (0, utils_1.binToHex)((0, hash_1.blakeHash)(exports.unsignedTxCodec.encode(unsignedTx)));
    }
    static toApiUnsignedTx(unsigned) {
        const txId = UnsignedTxCodec.txId(unsigned);
        const txIdBytes = (0, utils_1.hexToBinUnsafe)(txId);
        const version = unsigned.version;
        const networkId = unsigned.networkId;
        const gasAmount = unsigned.gasAmount;
        const gasPrice = unsigned.gasPrice.toString();
        const inputs = input_codec_1.InputCodec.toAssetInputs(unsigned.inputs);
        const fixedOutputs = asset_output_codec_1.AssetOutputCodec.toFixedAssetOutputs(txIdBytes, unsigned.fixedOutputs);
        let scriptOpt = undefined;
        if (unsigned.statefulScript.kind === 'Some') {
            scriptOpt = (0, utils_1.binToHex)(script_codec_1.scriptCodec.encode(unsigned.statefulScript.value));
        }
        return { txId, version, networkId, gasAmount, scriptOpt, gasPrice, inputs, fixedOutputs };
    }
    static fromApiUnsignedTx(unsignedTx) {
        const version = unsignedTx.version;
        const networkId = unsignedTx.networkId;
        const gasAmount = unsignedTx.gasAmount;
        const gasPrice = BigInt(unsignedTx.gasPrice);
        const inputs = input_codec_1.InputCodec.fromAssetInputs(unsignedTx.inputs);
        const fixedOutputs = asset_output_codec_1.AssetOutputCodec.fromFixedAssetOutputs(unsignedTx.fixedOutputs);
        const statefulScript = unsignedTx.scriptOpt !== undefined
            ? { kind: 'Some', value: script_codec_1.scriptCodec.decode((0, utils_1.hexToBinUnsafe)(unsignedTx.scriptOpt)) }
            : { kind: 'None', value: undefined };
        return { version, networkId, gasAmount, gasPrice, inputs, fixedOutputs, statefulScript };
    }
}
exports.UnsignedTxCodec = UnsignedTxCodec;
exports.unsignedTxCodec = new UnsignedTxCodec({
    version: codec_1.byteCodec,
    networkId: codec_1.byteCodec,
    statefulScript: script_codec_1.statefulScriptCodecOpt,
    gasAmount: compact_int_codec_1.i32Codec,
    gasPrice: compact_int_codec_1.u256Codec,
    inputs: input_codec_1.inputsCodec,
    fixedOutputs: asset_output_codec_1.assetOutputsCodec
});
