"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArrayCodec = void 0;
/*
Copyright 2018 - 2022 The Alephium Authors
This file is part of the alephium project.

The library is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

The library is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with the library. If not, see <http://www.gnu.org/licenses/>.
*/
const compact_int_codec_1 = require("./compact-int-codec");
const codec_1 = require("./codec");
const utils_1 = require("../utils");
class ArrayCodec extends codec_1.Codec {
    constructor(childCodec) {
        super();
        this.childCodec = childCodec;
    }
    encode(input) {
        const bytes = [compact_int_codec_1.i32Codec.encode(input.length)];
        for (const element of input) {
            bytes.push(this.childCodec.encode(element));
        }
        return (0, utils_1.concatBytes)(bytes);
    }
    _decode(input) {
        const length = compact_int_codec_1.i32Codec._decode(input);
        const array = [];
        for (let index = 0; index < length; index += 1) {
            array.push(this.childCodec._decode(input));
        }
        return array;
    }
}
exports.ArrayCodec = ArrayCodec;
