"use strict";
/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.Api = exports.HttpClient = exports.ContentType = exports.Currencies = exports.MaxSizeAddresses = exports.MaxSizeAddressesForTokens = exports.MaxSizeTokens = exports.TokensWithPrice = exports.TokenStdInterfaceId = exports.IntervalType = void 0;
/** IntervalType */
var IntervalType;
(function (IntervalType) {
    IntervalType["Daily"] = "daily";
    IntervalType["Hourly"] = "hourly";
    IntervalType["Weekly"] = "weekly";
})(IntervalType = exports.IntervalType || (exports.IntervalType = {}));
/** TokenStdInterfaceId */
var TokenStdInterfaceId;
(function (TokenStdInterfaceId) {
    TokenStdInterfaceId["Fungible"] = "fungible";
    TokenStdInterfaceId["NonFungible"] = "non-fungible";
    TokenStdInterfaceId["NonStandard"] = "non-standard";
})(TokenStdInterfaceId = exports.TokenStdInterfaceId || (exports.TokenStdInterfaceId = {}));
var TokensWithPrice;
(function (TokensWithPrice) {
    TokensWithPrice["WETH"] = "WETH";
    TokensWithPrice["ALPH"] = "ALPH";
    TokensWithPrice["USDT"] = "USDT";
    TokensWithPrice["AYIN"] = "AYIN";
    TokensWithPrice["DAI"] = "DAI";
    TokensWithPrice["USDC"] = "USDC";
    TokensWithPrice["WBTC"] = "WBTC";
})(TokensWithPrice = exports.TokensWithPrice || (exports.TokensWithPrice = {}));
var MaxSizeTokens;
(function (MaxSizeTokens) {
    MaxSizeTokens[MaxSizeTokens["Value80"] = 80] = "Value80";
})(MaxSizeTokens = exports.MaxSizeTokens || (exports.MaxSizeTokens = {}));
var MaxSizeAddressesForTokens;
(function (MaxSizeAddressesForTokens) {
    MaxSizeAddressesForTokens[MaxSizeAddressesForTokens["Value80"] = 80] = "Value80";
})(MaxSizeAddressesForTokens = exports.MaxSizeAddressesForTokens || (exports.MaxSizeAddressesForTokens = {}));
var MaxSizeAddresses;
(function (MaxSizeAddresses) {
    MaxSizeAddresses[MaxSizeAddresses["Value80"] = 80] = "Value80";
})(MaxSizeAddresses = exports.MaxSizeAddresses || (exports.MaxSizeAddresses = {}));
var Currencies;
(function (Currencies) {
    Currencies["Btc"] = "btc";
    Currencies["Eth"] = "eth";
    Currencies["Usd"] = "usd";
    Currencies["Eur"] = "eur";
    Currencies["Chf"] = "chf";
    Currencies["Gbp"] = "gbp";
    Currencies["Idr"] = "idr";
    Currencies["Vnd"] = "vnd";
    Currencies["Rub"] = "rub";
    Currencies["Try"] = "try";
    Currencies["Cad"] = "cad";
    Currencies["Aud"] = "aud";
})(Currencies = exports.Currencies || (exports.Currencies = {}));
require("cross-fetch/polyfill");
const utils_1 = require("./utils");
var ContentType;
(function (ContentType) {
    ContentType["Json"] = "application/json";
    ContentType["FormData"] = "multipart/form-data";
    ContentType["UrlEncoded"] = "application/x-www-form-urlencoded";
    ContentType["Text"] = "text/plain";
})(ContentType = exports.ContentType || (exports.ContentType = {}));
class HttpClient {
    constructor(apiConfig = {}) {
        this.baseUrl = '';
        this.securityData = null;
        this.abortControllers = new Map();
        this.customFetch = (...fetchParams) => fetch(...fetchParams);
        this.baseApiParams = {
            credentials: 'same-origin',
            headers: {},
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        };
        this.setSecurityData = (data) => {
            this.securityData = data;
        };
        this.contentFormatters = {
            [ContentType.Json]: (input) => input !== null && (typeof input === 'object' || typeof input === 'string') ? JSON.stringify(input) : input,
            [ContentType.Text]: (input) => (input !== null && typeof input !== 'string' ? JSON.stringify(input) : input),
            [ContentType.FormData]: (input) => Object.keys(input || {}).reduce((formData, key) => {
                const property = input[key];
                formData.append(key, property instanceof Blob
                    ? property
                    : typeof property === 'object' && property !== null
                        ? JSON.stringify(property)
                        : `${property}`);
                return formData;
            }, new FormData()),
            [ContentType.UrlEncoded]: (input) => this.toQueryString(input)
        };
        this.createAbortSignal = (cancelToken) => {
            if (this.abortControllers.has(cancelToken)) {
                const abortController = this.abortControllers.get(cancelToken);
                if (abortController) {
                    return abortController.signal;
                }
                return void 0;
            }
            const abortController = new AbortController();
            this.abortControllers.set(cancelToken, abortController);
            return abortController.signal;
        };
        this.abortRequest = (cancelToken) => {
            const abortController = this.abortControllers.get(cancelToken);
            if (abortController) {
                abortController.abort();
                this.abortControllers.delete(cancelToken);
            }
        };
        this.request = async ({ body, secure, path, type, query, format, baseUrl, cancelToken, ...params }) => {
            const secureParams = ((typeof secure === 'boolean' ? secure : this.baseApiParams.secure) &&
                this.securityWorker &&
                (await this.securityWorker(this.securityData))) ||
                {};
            const requestParams = this.mergeRequestParams(params, secureParams);
            const queryString = query && this.toQueryString(query);
            const payloadFormatter = this.contentFormatters[type || ContentType.Json];
            const responseFormat = format || requestParams.format;
            return this.customFetch(`${baseUrl || this.baseUrl || ''}${path}${queryString ? `?${queryString}` : ''}`, {
                ...requestParams,
                headers: {
                    ...(requestParams.headers || {}),
                    ...(type && type !== ContentType.FormData ? { 'Content-Type': type } : {})
                },
                signal: cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal,
                body: typeof body === 'undefined' || body === null ? null : payloadFormatter(body)
            }).then(async (response) => {
                const r = response;
                r.data = null;
                r.error = null;
                const data = !responseFormat
                    ? r
                    : await response[responseFormat]()
                        .then((data) => {
                        if (r.ok) {
                            r.data = data;
                        }
                        else {
                            r.error = data;
                        }
                        return r;
                    })
                        .catch((e) => {
                        r.error = e;
                        return r;
                    });
                if (cancelToken) {
                    this.abortControllers.delete(cancelToken);
                }
                return data;
            });
        };
        Object.assign(this, apiConfig);
    }
    encodeQueryParam(key, value) {
        const encodedKey = encodeURIComponent(key);
        return `${encodedKey}=${encodeURIComponent(typeof value === 'number' ? value : `${value}`)}`;
    }
    addQueryParam(query, key) {
        return this.encodeQueryParam(key, query[key]);
    }
    addArrayQueryParam(query, key) {
        const value = query[key];
        return value.map((v) => this.encodeQueryParam(key, v)).join('&');
    }
    toQueryString(rawQuery) {
        const query = rawQuery || {};
        const keys = Object.keys(query).filter((key) => 'undefined' !== typeof query[key]);
        return keys
            .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
            .join('&');
    }
    addQueryParams(rawQuery) {
        const queryString = this.toQueryString(rawQuery);
        return queryString ? `?${queryString}` : '';
    }
    mergeRequestParams(params1, params2) {
        return {
            ...this.baseApiParams,
            ...params1,
            ...(params2 || {}),
            headers: {
                ...(this.baseApiParams.headers || {}),
                ...(params1.headers || {}),
                ...((params2 && params2.headers) || {})
            }
        };
    }
}
exports.HttpClient = HttpClient;
/**
 * @title Alephium Explorer API
 * @version 1.0
 */
class Api extends HttpClient {
    constructor() {
        super(...arguments);
        this.contracts = {
            /**
             * @description Get sub contract addresses
             *
             * @tags Contracts
             * @name GetContractsContractAddressSubContracts
             * @request GET:/contracts/{contract_address}/sub-contracts
             */
            getContractsContractAddressSubContracts: (contractAddress, query, params = {}) => this.request({
                path: `/contracts/${contractAddress}/sub-contracts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get contract liveness
             *
             * @tags Contracts
             * @name GetContractsContractAddressCurrentLiveness
             * @request GET:/contracts/{contract_address}/current-liveness
             */
            getContractsContractAddressCurrentLiveness: (contractAddress, params = {}) => this.request({
                path: `/contracts/${contractAddress}/current-liveness`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get contract parent address if exist
             *
             * @tags Contracts
             * @name GetContractsContractAddressParent
             * @request GET:/contracts/{contract_address}/parent
             */
            getContractsContractAddressParent: (contractAddress, params = {}) => this.request({
                path: `/contracts/${contractAddress}/parent`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.addresses = {
            /**
             * @description Are the addresses used (at least 1 transaction)
             *
             * @tags Addresses, Addresses
             * @name PostAddressesUsed
             * @request POST:/addresses/used
             */
            postAddressesUsed: (data, params = {}) => this.request({
                path: `/addresses/used`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Addresses
             * @name GetAddressesAddressExportTransactionsCsv
             * @request GET:/addresses/{address}/export-transactions/csv
             */
            getAddressesAddressExportTransactionsCsv: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/export-transactions/csv`,
                method: 'GET',
                query: query,
                format: 'text',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get address balance
             *
             * @tags Addresses
             * @name GetAddressesAddressBalance
             * @request GET:/addresses/{address}/balance
             */
            getAddressesAddressBalance: (address, params = {}) => this.request({
                path: `/addresses/${address}/balance`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List mempool transactions of a given address
             *
             * @tags Addresses
             * @name GetAddressesAddressMempoolTransactions
             * @request GET:/addresses/{address}/mempool/transactions
             */
            getAddressesAddressMempoolTransactions: (address, params = {}) => this.request({
                path: `/addresses/${address}/mempool/transactions`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List address tokens
             *
             * @tags Addresses
             * @name GetAddressesAddressTokensTokenIdTransactions
             * @request GET:/addresses/{address}/tokens/{token_id}/transactions
             */
            getAddressesAddressTokensTokenIdTransactions: (address, tokenId, query, params = {}) => this.request({
                path: `/addresses/${address}/tokens/${tokenId}/transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get total transactions of a given address
             *
             * @tags Addresses
             * @name GetAddressesAddressTotalTransactions
             * @request GET:/addresses/{address}/total-transactions
             */
            getAddressesAddressTotalTransactions: (address, params = {}) => this.request({
                path: `/addresses/${address}/total-transactions`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List transactions of a given address within a time-range
             *
             * @tags Addresses
             * @name GetAddressesAddressTimerangedTransactions
             * @request GET:/addresses/{address}/timeranged-transactions
             */
            getAddressesAddressTimerangedTransactions: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/timeranged-transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List transactions of a given address
             *
             * @tags Addresses
             * @name GetAddressesAddressTransactions
             * @request GET:/addresses/{address}/transactions
             */
            getAddressesAddressTransactions: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Addresses
             * @name GetAddressesAddressAmountHistory
             * @request GET:/addresses/{address}/amount-history
             */
            getAddressesAddressAmountHistory: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/amount-history`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List transactions for given addresses
             *
             * @tags Addresses
             * @name PostAddressesTransactions
             * @request POST:/addresses/transactions
             */
            postAddressesTransactions: (query, data, params = {}) => this.request({
                path: `/addresses/transactions`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Addresses
             * @name GetAddressesAddressAmountHistoryDeprecated
             * @request GET:/addresses/{address}/amount-history-DEPRECATED
             * @deprecated
             */
            getAddressesAddressAmountHistoryDeprecated: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/amount-history-DEPRECATED`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List address tokens
             *
             * @tags Addresses
             * @name GetAddressesAddressTokens
             * @request GET:/addresses/{address}/tokens
             * @deprecated
             */
            getAddressesAddressTokens: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/tokens`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get address tokens with balance
             *
             * @tags Addresses
             * @name GetAddressesAddressTokensBalance
             * @request GET:/addresses/{address}/tokens-balance
             */
            getAddressesAddressTokensBalance: (address, query, params = {}) => this.request({
                path: `/addresses/${address}/tokens-balance`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get address balance of given token
             *
             * @tags Addresses
             * @name GetAddressesAddressTokensTokenIdBalance
             * @request GET:/addresses/{address}/tokens/{token_id}/balance
             */
            getAddressesAddressTokensTokenIdBalance: (address, tokenId, params = {}) => this.request({
                path: `/addresses/${address}/tokens/${tokenId}/balance`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get address information
             *
             * @tags Addresses
             * @name GetAddressesAddress
             * @request GET:/addresses/{address}
             */
            getAddressesAddress: (address, params = {}) => this.request({
                path: `/addresses/${address}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get public key of p2pkh addresses, the address needs to have at least one input.
             *
             * @tags Addresses
             * @name GetAddressesAddressPublicKey
             * @request GET:/addresses/{address}/public-key
             */
            getAddressesAddressPublicKey: (address, params = {}) => this.request({
                path: `/addresses/${address}/public-key`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.infos = {
            /**
             * @description Get token supply list
             *
             * @tags Infos
             * @name GetInfosSupply
             * @request GET:/infos/supply
             */
            getInfosSupply: (query, params = {}) => this.request({
                path: `/infos/supply`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get the ALPH locked supply
             *
             * @tags Infos
             * @name GetInfosSupplyLockedAlph
             * @request GET:/infos/supply/locked-alph
             */
            getInfosSupplyLockedAlph: (params = {}) => this.request({
                path: `/infos/supply/locked-alph`,
                method: 'GET',
                format: 'text',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get explorer informations
             *
             * @tags Infos
             * @name GetInfos
             * @request GET:/infos
             */
            getInfos: (params = {}) => this.request({
                path: `/infos`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get the ALPH circulating supply
             *
             * @tags Infos
             * @name GetInfosSupplyCirculatingAlph
             * @request GET:/infos/supply/circulating-alph
             */
            getInfosSupplyCirculatingAlph: (params = {}) => this.request({
                path: `/infos/supply/circulating-alph`,
                method: 'GET',
                format: 'text',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get the ALPH total supply
             *
             * @tags Infos
             * @name GetInfosSupplyTotalAlph
             * @request GET:/infos/supply/total-alph
             */
            getInfosSupplyTotalAlph: (params = {}) => this.request({
                path: `/infos/supply/total-alph`,
                method: 'GET',
                format: 'text',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get the total number of transactions
             *
             * @tags Infos
             * @name GetInfosTotalTransactions
             * @request GET:/infos/total-transactions
             */
            getInfosTotalTransactions: (params = {}) => this.request({
                path: `/infos/total-transactions`,
                method: 'GET',
                format: 'text',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get the ALPH reserved supply
             *
             * @tags Infos
             * @name GetInfosSupplyReservedAlph
             * @request GET:/infos/supply/reserved-alph
             */
            getInfosSupplyReservedAlph: (params = {}) => this.request({
                path: `/infos/supply/reserved-alph`,
                method: 'GET',
                format: 'text',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List latest height for each chain
             *
             * @tags Infos
             * @name GetInfosHeights
             * @request GET:/infos/heights
             */
            getInfosHeights: (params = {}) => this.request({
                path: `/infos/heights`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get the average block time for each chain
             *
             * @tags Infos
             * @name GetInfosAverageBlockTimes
             * @request GET:/infos/average-block-times
             */
            getInfosAverageBlockTimes: (params = {}) => this.request({
                path: `/infos/average-block-times`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.utils = {
            /**
             * @description Perform a sanity check
             *
             * @tags Utils
             * @name PutUtilsSanityCheck
             * @request PUT:/utils/sanity-check
             */
            putUtilsSanityCheck: (params = {}) => this.request({
                path: `/utils/sanity-check`,
                method: 'PUT',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Update global log level, accepted: TRACE, DEBUG, INFO, WARN, ERROR
             *
             * @tags Utils
             * @name PutUtilsUpdateGlobalLoglevel
             * @request PUT:/utils/update-global-loglevel
             */
            putUtilsUpdateGlobalLoglevel: (data, params = {}) => this.request({
                path: `/utils/update-global-loglevel`,
                method: 'PUT',
                body: data,
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Update logback values
             *
             * @tags Utils
             * @name PutUtilsUpdateLogConfig
             * @request PUT:/utils/update-log-config
             */
            putUtilsUpdateLogConfig: (data, params = {}) => this.request({
                path: `/utils/update-log-config`,
                method: 'PUT',
                body: data,
                type: ContentType.Json,
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.tokens = {
            /**
             * @description Return metadata for the given fungible tokens, if metadata doesn't exist or token isn't a fungible, it won't be in the output list
             *
             * @tags Tokens
             * @name PostTokensFungibleMetadata
             * @request POST:/tokens/fungible-metadata
             */
            postTokensFungibleMetadata: (data, params = {}) => this.request({
                path: `/tokens/fungible-metadata`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List given tokens information
             *
             * @tags Tokens
             * @name PostTokens
             * @request POST:/tokens
             */
            postTokens: (data, params = {}) => this.request({
                path: `/tokens`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List token information
             *
             * @tags Tokens
             * @name GetTokens
             * @request GET:/tokens
             */
            getTokens: (query, params = {}) => this.request({
                path: `/tokens`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List token transactions
             *
             * @tags Tokens
             * @name GetTokensTokenIdTransactions
             * @request GET:/tokens/{token_id}/transactions
             */
            getTokensTokenIdTransactions: (tokenId, query, params = {}) => this.request({
                path: `/tokens/${tokenId}/transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Return metadata for the given nft collection addresses, if metadata doesn't exist or address isn't a nft collection, it won't be in the output list
             *
             * @tags Tokens
             * @name PostTokensNftCollectionMetadata
             * @request POST:/tokens/nft-collection-metadata
             */
            postTokensNftCollectionMetadata: (data, params = {}) => this.request({
                path: `/tokens/nft-collection-metadata`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List token addresses
             *
             * @tags Tokens
             * @name GetTokensTokenIdAddresses
             * @request GET:/tokens/{token_id}/addresses
             */
            getTokensTokenIdAddresses: (tokenId, query, params = {}) => this.request({
                path: `/tokens/${tokenId}/addresses`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Return metadata for the given nft tokens, if metadata doesn't exist or token isn't a nft, it won't be in the output list
             *
             * @tags Tokens
             * @name PostTokensNftMetadata
             * @request POST:/tokens/nft-metadata
             */
            postTokensNftMetadata: (data, params = {}) => this.request({
                path: `/tokens/nft-metadata`,
                method: 'POST',
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.market = {
            /**
             * No description
             *
             * @tags Market
             * @name PostMarketPrices
             * @request POST:/market/prices
             */
            postMarketPrices: (query, data, params = {}) => this.request({
                path: `/market/prices`,
                method: 'POST',
                query: query,
                body: data,
                type: ContentType.Json,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * No description
             *
             * @tags Market
             * @name GetMarketPricesSymbolCharts
             * @request GET:/market/prices/{symbol}/charts
             */
            getMarketPricesSymbolCharts: (symbol, query, params = {}) => this.request({
                path: `/market/prices/${symbol}/charts`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.blocks = {
            /**
             * @description Get block's transactions
             *
             * @tags Blocks
             * @name GetBlocksBlockHashTransactions
             * @request GET:/blocks/{block_hash}/transactions
             */
            getBlocksBlockHashTransactions: (blockHash, query, params = {}) => this.request({
                path: `/blocks/${blockHash}/transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get a block with hash
             *
             * @tags Blocks
             * @name GetBlocksBlockHash
             * @request GET:/blocks/{block_hash}
             */
            getBlocksBlockHash: (blockHash, params = {}) => this.request({
                path: `/blocks/${blockHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description List latest blocks
             *
             * @tags Blocks
             * @name GetBlocks
             * @request GET:/blocks
             */
            getBlocks: (query, params = {}) => this.request({
                path: `/blocks`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.transactions = {
            /**
             * @description Get a transaction with hash
             *
             * @tags Transactions
             * @name GetTransactionsTransactionHash
             * @request GET:/transactions/{transaction_hash}
             */
            getTransactionsTransactionHash: (transactionHash, params = {}) => this.request({
                path: `/transactions/${transactionHash}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.contractEvents = {
            /**
             * @description Get contract events by transaction id
             *
             * @tags Contract events
             * @name GetContractEventsTransactionIdTransactionId
             * @request GET:/contract-events/transaction-id/{transaction_id}
             */
            getContractEventsTransactionIdTransactionId: (transactionId, params = {}) => this.request({
                path: `/contract-events/transaction-id/${transactionId}`,
                method: 'GET',
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get contract events by contract address
             *
             * @tags Contract events
             * @name GetContractEventsContractAddressContractAddress
             * @request GET:/contract-events/contract-address/{contract_address}
             */
            getContractEventsContractAddressContractAddress: (contractAddress, query, params = {}) => this.request({
                path: `/contract-events/contract-address/${contractAddress}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description Get contract events by contract and input addresses
             *
             * @tags Contract events
             * @name GetContractEventsContractAddressContractAddressInputAddressInputAddress
             * @request GET:/contract-events/contract-address/{contract_address}/input-address/{input_address}
             */
            getContractEventsContractAddressContractAddressInputAddressInputAddress: (contractAddress, inputAddress, query, params = {}) => this.request({
                path: `/contract-events/contract-address/${contractAddress}/input-address/${inputAddress}`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.charts = {
            /**
             * @description `interval-type` query param: hourly, daily
             *
             * @tags Charts
             * @name GetChartsTransactionsCountPerChain
             * @summary Get transaction count history per chain
             * @request GET:/charts/transactions-count-per-chain
             */
            getChartsTransactionsCountPerChain: (query, params = {}) => this.request({
                path: `/charts/transactions-count-per-chain`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description `interval-type` query param: hourly, daily
             *
             * @tags Charts
             * @name GetChartsTransactionsCount
             * @summary Get transaction count history
             * @request GET:/charts/transactions-count
             */
            getChartsTransactionsCount: (query, params = {}) => this.request({
                path: `/charts/transactions-count`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse),
            /**
             * @description `interval-type` query param: hourly, daily
             *
             * @tags Charts
             * @name GetChartsHashrates
             * @summary Get hashrate chart in H/s
             * @request GET:/charts/hashrates
             */
            getChartsHashrates: (query, params = {}) => this.request({
                path: `/charts/hashrates`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
        this.mempool = {
            /**
             * @description list mempool transactions
             *
             * @tags Mempool
             * @name GetMempoolTransactions
             * @request GET:/mempool/transactions
             */
            getMempoolTransactions: (query, params = {}) => this.request({
                path: `/mempool/transactions`,
                method: 'GET',
                query: query,
                format: 'json',
                ...params
            }).then(utils_1.convertHttpResponse)
        };
    }
}
exports.Api = Api;
